import styled from "@emotion/styled"
import moment from "moment"
import Link from "next/link"
import { NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"

export type StockTableMessages = {
  stockInfo: string
  bonds: string
  stockTicker: string
  price: string
  change: string
  volume: string
  ytm: string
  averageDuration: string
  weekRange: string
  lastUpdate: string
  updated: string
  stockExWeb: string
  tostock: string
  tobonds: string
  changeinpercent: string
  DailyLow: string
  DailyHigh: string
  AnnualLow: string
  AnnualHigh: string
}

const StyledTable = styled.div`
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 24px 0;
  }
`

const StyledItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLine = styled.div`
  height: 1px;
  background-color: #6f6f6f;
  width: 100%;
`

const StyledBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)`
  width: 153px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.black};
  border-radius: 200px;
  text-decoration: none;
  text-align: center;
`

type Props = {
  data: any
  messages: StockTableMessages
}

function StockTable({ data, messages }: Props) {
  const tableData = [
    {
      title: messages.stockTicker,
      value: "symbol",
    },
    {
      title: messages.price,
      value: "securityLastRate",
    },
    {
      title: messages.change,
      value: "changeValue",
    },
    {
      title: messages.changeinpercent,
      value: "securityPercentageChange",
    },
    {
      title: messages.DailyLow,
      value: "dailyLow",
    },
    {
      title: messages.DailyHigh,
      value: "dailyHigh",
    },
    {
      title: messages.AnnualLow,
      value: "annualLow",
    },
    {
      title: messages.AnnualHigh,
      value: "annualHigh",
    },
    {
      title: messages.ytm,
      value: "securityDailyAggVolume",
    },
  ]
  return (
    <StyledTable>
      {tableData.map((item: any, index: any) => (
        <StyledItemDiv key={index}>
          <NewText
            component={"p"}
            desktopFontSize={16}
            desktopLineHeight={"1.45"}
            desktopFontWeight={400}
            mobileFontSize={14}
            mobileLineHeight={"20px"}
            color={colors.black}
          >
            {item.title}
          </NewText>
          <NewText
            component={"p"}
            desktopFontSize={16}
            desktopLineHeight={"1.45"}
            desktopFontWeight={400}
            mobileFontSize={14}
            mobileLineHeight={"20px"}
            color={
              item?.value === "changeValue" || item?.value === "securityPercentageChange"
                ? data?.[item.value] > 0
                  ? "#008015"
                  : "red"
                : colors.black
            }
            style={{ unicodeBidi: "plaintext" }}
          >
            {data[item.value]}
            {item?.value === "securityPercentageChange" ? "%" : ""}
          </NewText>
        </StyledItemDiv>
      ))}
      <StyledLine />

      <StyledBottomDiv>
        <NewText
          component={"p"}
          desktopFontSize={14}
          desktopLineHeight={"20px"}
          desktopFontWeight={400}
          mobileFontSize={12}
          mobileLineHeight={"17px"}
          color={colors.black}
        >
          *{messages.updated} {moment(data?.lastTransaction).format("DD/MM/YYYY HH:mm")}
        </NewText>

        <StyledLink href={"https://www.tase.co.il/he"}>
          <NewText
            component={"p"}
            desktopFontSize={16}
            desktopLineHeight={"23px"}
            desktopFontWeight={600}
            color={colors.black}
          >
            {messages.stockExWeb}
          </NewText>
        </StyledLink>
      </StyledBottomDiv>
    </StyledTable>
  )
}

export default StockTable
