"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { LobbyAvailableFilters } from "api"
import Link from "next/link"
import { LeftArrow, NewText, RightArrow, ShareIcon } from "ui"
import { sharedColors as colors, sharedColors } from "ui/theme/colors"
import { Job, jobClick } from "utils"
import { CareerLobbyMessages } from "../Careers"

const StyledCard = styled.div`
  width: 100%;
  min-height: 220px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f7f7f7;
  border-width: 0px 0px 2px 0px;
  justify-content: space-between;
  box-shadow: 0px 4px 12px 0px #00000029;
  height: 100%;
`
const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const StyledBottomIntro = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  background-color: ${sharedColors.oldBrandBlue};
  height: 100%;
`
const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledDetail = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const LastItem = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledLinkArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: end;
  text-decoration: none;
  cursor: pointer;
`

const StyledLinkWrapp = styled(Link)`
  text-decoration: none;
`

function JobCard({
  job,
  locale,
  messages,
  filters,
}: {
  job: Job
  locale: string
  messages: CareerLobbyMessages
  filters: LobbyAvailableFilters
}) {
  const onShare = async () => {
    const shareData = {
      title: job.jobName || "",
      url: window.location.href + "/" + `${locale === "en" ? "en" : ""}` + job.orderId,
    }
    if (navigator.share && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData)
      } catch (err) {
        console.error(err)
      }
    } else {
      navigator.clipboard.writeText(shareData.url)
    }
  }

  return (
    <StyledLinkWrapp
      href={`/${locale === "en" ? "en/" : ""}career/${job.orderId}`}
      onClick={() => jobClick(messages.viewJob, job.jobName ? job.jobName : "")}
    >
      <StyledCard>
        <StyledIntro>
          <StyledHeader>
            <TopHeader>
              <NewText
                component={"span"}
                desktopFontSize={14}
                color={colors.white}
                mobileFontSize={14}
              >
                {`${messages.jobNo} :${job.orderId}`}
              </NewText>
              <Button
                variant="text"
                sx={{ padding: 0, minWidth: 0, height: 24 }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onShare()
                }}
              >
                <ShareIcon width={20} height={20} color="white" />
              </Button>
            </TopHeader>
            <NewText
              component={"h3"}
              desktopFontSize={18}
              mobileFontSize={16}
              color={colors.white}
              desktopLineHeight={"1.45"}
            >
              {job.jobName}
            </NewText>
          </StyledHeader>

          <StyledBottomIntro>
            <StyledDetail>
              <NewText
                component={"span"}
                desktopFontWeight={600}
                desktopFontSize={16}
                mobileFontSize={16}
                color={colors.black}
                desktopLineHeight={"1.44"}
              >
                אזור:
              </NewText>
              <NewText
                component={"span"}
                desktopFontSize={16}
                mobileFontSize={16}
                color={colors.black}
                desktopLineHeight={"1.44"}
              >
                {job.areaName}
              </NewText>
            </StyledDetail>
            <StyledDetail>
              <NewText
                component={"span"}
                desktopFontWeight={600}
                desktopFontSize={16}
                mobileFontSize={16}
                color={colors.black}
                desktopLineHeight={"1.44"}
              >
                תחום:
              </NewText>
              <NewText
                component={"span"}
                desktopFontSize={16}
                mobileFontSize={16}
                color={colors.black}
                desktopLineHeight={"1.44"}
              >
                {job.professionName}
              </NewText>
            </StyledDetail>
            <StyledDetail>
              <NewText
                component={"span"}
                desktopFontWeight={600}
                desktopFontSize={16}
                mobileFontSize={16}
                color={colors.black}
                desktopLineHeight={"1.44"}
              >
                חברה:
              </NewText>
              <NewText
                component={"span"}
                desktopFontSize={16}
                mobileFontSize={16}
                color={colors.black}
                desktopLineHeight={"1.44"}
              >
                {filters.avialableCompanies.filter((filter) => filter.id === job.branchId)[0]?.name}
              </NewText>
            </StyledDetail>
            <LastItem>
              <StyledDetail>
                <NewText
                  component={"span"}
                  desktopFontWeight={600}
                  desktopFontSize={16}
                  mobileFontSize={16}
                  color={colors.black}
                  desktopLineHeight={"1.44"}
                >
                  היקף משרה:
                </NewText>
                <NewText
                  component={"span"}
                  desktopFontSize={16}
                  mobileFontSize={16}
                  color={colors.black}
                  desktopLineHeight={"1.44"}
                >
                  {
                    filters.avialableJobScopes.filter((filter) => filter.id === job.jobScopeId)[0]
                      ?.name
                  }
                </NewText>
              </StyledDetail>
              <StyledLinkArrow>
                {locale === "he" ? <LeftArrow color="black" /> : <RightArrow color="black" />}
              </StyledLinkArrow>
            </LastItem>
          </StyledBottomIntro>
        </StyledIntro>
      </StyledCard>
    </StyledLinkWrapp>
  )
}

export default JobCard
