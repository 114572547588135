"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Link from "next/link"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { Container, NewText, useIsDesktop, useTransitionUpStyles } from "ui"
import { delayTime, styleObjectToCss, useInViewConfig } from "ui/transitions/utils"
import { extractProperty } from "utils"

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: baseline;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
`
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const StyledSearch = styled.div`
  grid-row: 2;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
    justify-content: end;
    grid-column: 2;
    grid-row: 1;
  }
`
const StyledWrapper = styled.div`
  background: url("/images/heroBG.png"), #2768a3;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
  > div > div {
    background: transparent;
    display: inline-block;
  }
  div,
  h1,
  p {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
  }
  h1 {
    font-size: 40px;
    max-width: 750px;
    line-height: 46px;
  }
  div,
  p {
    font-size: 16px;
    line-height: 23px;
  }
  p {
    max-width: 675px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 44px;
    margin-left: 0;
    margin-right: 0;
    background-position-y: center;
    border-radius: unset;
    h1 {
      font-size: 48px;
      line-height: 60px;
    }
    div,
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: 16px;
  border-color: white;
  :hover {
    border-color: white;
  }
`

type Props = {
  data: any
  maxWidth?: string
  children: React.ReactNode
  hasBanner: boolean
  projectButtonUrl?: { url: string; name: string }
}

export const ContentText: React.FC<Props> = ({
  data,
  maxWidth,
  children,
  hasBanner,
  projectButtonUrl,
}) => {
  const title = extractProperty(data, "conetntTitle")
  const innerText = extractProperty(data, "innerText")
  const isDesktop = useIsDesktop()
  const { ref, inView } = useInView(useInViewConfig)
  const subTitle = extractProperty(data, "subTitle")
  const titleTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  if (!hasBanner) {
    return (
      <StyledWrapper>
        <Container
          style={{
            paddingBottom: isDesktop ? "62px" : "24px",
            paddingTop: isDesktop ? "62px" : "24px",
          }}
          containerRef={ref}
        >
          <StyledContainer>
            <StyledSearch>{children}</StyledSearch>
            <StyledText>
              <NewText
                desktopFontSize={48}
                desktopFontWeight={600}
                desktopLineHeight="110%"
                mobileFontSize={30}
                mobileLineHeight={"112.5%"}
                component="h1"
                color={"black"}
                cssString={styleObjectToCss(titleTransitionStyles)}
              >
                {title}
              </NewText>
              {subTitle && (
                <NewText
                  mobileFontSize={24}
                  mobileLineHeight="28px"
                  desktopFontSize={32}
                  desktopLineHeight="36px"
                  component="p"
                  cssString={styleObjectToCss(titleTransitionStyles)}
                >
                  {subTitle}
                </NewText>
              )}
              <NewText
                desktopFontSize={14}
                desktopFontWeight="400"
                desktopLineHeight="20px"
                mobileFontSize={12}
                mobileFontWeight="400"
                mobileLineHeight={"17px"}
                component="div"
                color={"black"}
                dangerouslySetInnerHTML={{ __html: innerText }}
                style={{ margin: 0, padding: 0 }}
                maxWidth={maxWidth || "687px"}
                cssString={styleObjectToCss(descriptionTransitionStyles)}
              />
            </StyledText>
            {projectButtonUrl && projectButtonUrl.url ? (
              <Link target="_blank" href={projectButtonUrl.url}>
                <StyledButton variant="outlined">
                  <NewText
                    component={"span"}
                    color="white"
                    desktopFontSize={18}
                    desktopLineHeight={"normal"}
                    desktopFontWeight={600}
                    mobileFontSize={16}
                  >
                    {projectButtonUrl.name}
                  </NewText>
                </StyledButton>
              </Link>
            ) : null}
          </StyledContainer>
        </Container>
      </StyledWrapper>
    )
  }
  return (
    <Container
      style={{
        paddingBottom: isDesktop ? "62px" : "24px",
        paddingTop: isDesktop ? "62px" : "24px",
      }}
      containerRef={ref}
    >
      <StyledContainer>
        <StyledSearch>{children}</StyledSearch>
        <StyledText>
          <NewText
            desktopFontSize={40}
            desktopFontWeight="400"
            desktopLineHeight="110%"
            mobileFontSize={30}
            mobileFontWeight="400"
            mobileLineHeight={"112.5%"}
            component="h1"
            color={"black"}
            cssString={styleObjectToCss(titleTransitionStyles)}
          >
            {title}
          </NewText>
          {subTitle && (
            <NewText
              color={"black"}
              mobileFontSize={24}
              mobileLineHeight="28px"
              desktopFontSize={32}
              desktopLineHeight="36px"
              component="p"
              cssString={styleObjectToCss(titleTransitionStyles)}
            >
              {subTitle}
            </NewText>
          )}
          <NewText
            desktopFontSize={18}
            desktopFontWeight="400"
            desktopLineHeight="26px"
            mobileFontSize={16}
            mobileFontWeight="400"
            mobileLineHeight={"23px"}
            component="div"
            color={"black"}
            dangerouslySetInnerHTML={{ __html: innerText }}
            style={{ marginBlockStart: 16, padding: 0 }}
            maxWidth={maxWidth || "687px"}
            cssString={styleObjectToCss(descriptionTransitionStyles)}
          />
        </StyledText>
      </StyledContainer>
    </Container>
  )
}
export default ContentText
