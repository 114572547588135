"use client"
import styled from "@emotion/styled"
import "swiper/css"
import { Container, FaqIcon, NewText } from "ui"
import { extractProperty } from "utils"

const StyledContainer = styled.div`
  margin-top: 32px;
  padding: 0;
  background: white;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    padding: 0 0 40px 0;
  }
  p {
    margin: 0px;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
`
type Props = {
  data: any
}

export const TitleContent: React.FC<Props> = ({ data }) => {
  const title = extractProperty(data, "conetntTitle")
  const innerText = extractProperty(data, "innerText")
  return (
    <Container>
      <StyledContainer>
        <StyledTextWrapper>
          <FaqIcon />
          <NewText
            desktopFontSize={40}
            desktopFontWeight="400"
            desktopLineHeight="110%"
            mobileFontSize={32}
            mobileFontWeight="400"
            mobileLineHeight={"112.5%"}
            component="h1"
            color={"black"}
          >
            {title}
          </NewText>
        </StyledTextWrapper>
        <NewText
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          mobileFontSize={16}
          mobileFontWeight="400"
          mobileLineHeight={"23px"}
          component="div"
          color={"black"}
          dangerouslySetInnerHTML={{ __html: innerText }}
          maxWidth={"850px"}
          marginTop={"8px"}
        />
      </StyledContainer>
    </Container>
  )
}
export default TitleContent
