import styled from "@emotion/styled"
import { Button, Pagination, PaginationItem } from "@mui/material"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { Container, FilterIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment } from "utils"
import FiltersModal from "../Projects/Marketing/FiltersModal"
import { ApState, createDefaultState, filterApartments } from "../Projects/Marketing/PropertyTab"
import { ProjectsMessages } from "../Projects/Projects"
import MarketingBanner from "./MarketingBanner"
import RentAccordion from "./RentAccordion"

type Props = {
  apartForPage: Apartment[]
  allApartments: Apartment[]
  total: number
  pageData: any
  messages: ProjectsMessages
  selectedFilters: string[]
  setSelectedFilters: Dispatch<SetStateAction<string[]>>
  locale: string
  allApChar: string[]
  allBuChar: string[]
}

const StyledApartmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 32px;
    padding-top: 32px;
    padding-bottom: 40px;
  }
`

const StyledPaginationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 40px;
  }
  li {
    padding-left: 4px;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-left: 16px;
    }
    &:last-child {
      a {
        border: none !important;
      }
    }
    &:first-child {
      a {
        border: none !important;
      }
    }
    a {
      font-size: 16px;
      border: 1px solid #f7f7f7;
    }
    .Mui-selected {
      background-color: #2768a3;
    }
  }
`

const StyledTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding-top: 32px;
  }
`

const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${sharedColors.green};
  border-radius: 50px;
`

const FilterButton = styled(Button)`
  padding: 0;
  min-width: 0;
  svg:hover > rect:first-of-type {
    fill: black;
  }
  svg:hover > rect:nth-of-type(2) {
    stroke: white;
  }
  svg:hover > path {
    fill: white;
  }
`

const StyledFilterCount = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-color: black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 10px;
`

const StyledTotalDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const NoResultButton = styled(Button)`
  text-decoration: none;
  border-bottom: 1px solid black;
  width: fit-content;
  font-size: 14px;
  line-height: 10px;
  color: black;
  border-radius: 0;
  align-self: flex-start;
  height: max-content;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 14px;
    line-height: 14px;
  }
  :hover {
    text-decoration: none;
  }
`

function filterAllApartments({
  apartments,
  selectedCities,
  state,
}: {
  apartments: Apartment[]
  selectedCities: string[]
  state: ApState
}) {
  let filteredApartments: Apartment[] = apartments
  if (!selectedCities.includes("all")) {
    filteredApartments = filteredApartments.filter((el) =>
      selectedCities.some((city) => city === el.city),
    )
  }
  const finalAp = filterApartments({ apartments: filteredApartments, state })
  return finalAp
}

function Apartments({
  apartForPage,
  total,
  pageData,
  messages,
  allApartments,
  selectedFilters,
  setSelectedFilters,
  locale,
  allApChar,
  allBuChar,
}: Props) {
  const [pageNum, setPageNum] = useState(1)
  const [filtersCount, setFiltersCount] = useState(0)

  const defaultState = useMemo(
    () => createDefaultState({ apartmProp: allApChar, buildProp: allBuChar }),
    [allApChar, allBuChar],
  )

  const [state, setState] = useState(defaultState)
  const [modalOpen, setModalOpen] = useState(false)
  const showDefault = selectedFilters.includes("all") && filtersCount === 0
  const perPage = 9
  const totalPages = Math.ceil(total / perPage)
  const t = useTranslations("BuildingInfo")
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNum(value)
  }
  const filteredAllApartments = filterAllApartments({
    apartments: allApartments,
    selectedCities: selectedFilters,
    state,
  }).slice(0, 5)
  const totalNumber = showDefault ? total : filteredAllApartments.length
  const pathname = usePathname()
  const displayedApartments = showDefault ? apartForPage : filteredAllApartments
  const handleReset = () => {
    setState(defaultState)
    setFiltersCount(0)
  }
  const handleAllReset = () => {
    setState(defaultState)
    setFiltersCount(0)
    setSelectedFilters(["all"])
  }
  return (
    <Container>
      <StyledApartmentsContainer>
        <StyledTop>
          <>
            {totalNumber > 0 ? (
              <StyledTotalDiv>
                <StyledDot />
                <NewText
                  component={"p"}
                  desktopFontSize={14}
                  desktopLineHeight={"20px"}
                  mobileFontSize={12}
                  mobileLineHeight={"17px"}
                  color={sharedColors.black}
                >
                  {t("totalRent")} <strong>{totalNumber}</strong> {t("availableApartmentsRent")}
                </NewText>
              </StyledTotalDiv>
            ) : (
              <NewText
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                color={sharedColors.black}
              >
                {t("totalRent")} <strong>{totalNumber}</strong> {t("availableApartments")}
              </NewText>
            )}
            <FilterButton onClick={() => setModalOpen(true)}>
              {filtersCount > 0 && <StyledFilterCount>{filtersCount}</StyledFilterCount>}
              <FilterIcon />
            </FilterButton>
          </>

          {modalOpen ? (
            <FiltersModal
              allApChar={allApChar}
              allBuChar={allBuChar}
              handleClose={() => setModalOpen(false)}
              open={modalOpen}
              locale={locale}
              state={state}
              setState={setState}
              setFiltersCount={setFiltersCount}
              filtersCount={filtersCount}
              defaultState={defaultState}
              handleReset={handleReset}
            />
          ) : null}
        </StyledTop>
        {displayedApartments.map((item, index: number) => {
          return (
            <>
              {pageNum === 1 && index === 5 && displayedApartments.length !== 6 && (
                <MarketingBanner data={pageData} />
              )}

              {pageNum !== 1 && index % 4 === 0 && index !== 0 && (
                <MarketingBanner data={pageData} />
              )}
              <RentAccordion
                apartment={item}
                formSubjects={{}}
                messages={messages}
                pageNum={pageNum}
                bmbyId={Number(item.priorityID)}
              />
              {pageNum === 1 && index === 5 && displayedApartments.length === 6 && (
                <MarketingBanner data={pageData} />
              )}
            </>
          )
        })}
        {pageNum === 1 && displayedApartments.length < 5 && displayedApartments.length && (
          <MarketingBanner data={pageData} />
        )}
        {displayedApartments.length < 1 && (
          <NoResultButton onClick={handleAllReset}>איפוס מסננים</NoResultButton>
        )}
      </StyledApartmentsContainer>
      {showDefault && (
        <StyledPaginationDiv>
          <Pagination
            count={totalPages}
            color="primary"
            onChange={handleChange}
            defaultPage={1}
            page={pageNum}
            size="small"
            renderItem={(item) => (
              <PaginationItem
                component={Link}
                href={`${pathname}?page=${item.page}`}
                scroll={false}
                {...item}
              />
            )}
          />
        </StyledPaginationDiv>
      )}
    </Container>
  )
}

export default Apartments
