"use client"

import styled from "@emotion/styled"
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material"
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers"
import moment from "moment"
import { useLocale, useTranslations } from "next-intl"
import Image from "next/image"
import { useEffect, useState } from "react"
import { CircleXIcon, Desktop, Mobile, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment, getUmbracoMediaUrl } from "utils"
import { choosenProject } from "utils/gtm"
import { ProjectsMessages } from "../Projects/Projects"
import RentAccordionInner from "./RentAccordionInner"

const UpperBox = styled.div`
  display: flex;
  align-items: stretch;
  gap: 24px;
`

const ItemBox = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  /* height: 120px; */
  width: 100%;
  padding: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 24px;
  }
  border-radius: 16px;
  border-bottom-left-radius: ${({ expanded }) => (expanded ? "0" : "16")}px;
  border-bottom-right-radius: ${({ expanded }) => (expanded ? "0" : "16")}px;
  box-shadow: 0px 4px 12px 0px #00000029;
`

const StyledImage = styled(Image)`
  border-radius: 8px;
  overflow: hidden;
`

const ContentPart = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: end;
    flex-direction: row;
    padding: 8px;
  }
`

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-self: baseline;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 180px;
  }
`

const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  /* flex: 1; */
  gap: 16px;
  justify-content: space-between;
  //background-color: #ee0808;
  flex: 0.97;
  //width: 65%;
  ${(props) => props.theme.breakpoints.up("md")} {
    border-inline-start: 2px solid #f7f7f7;
    /* border-inline-end: 2px solid #f7f7f7; */
    padding: 0 17.83px;
  }
`

const LargeDivider = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
  height: 100%;
  width: 2px;
  background-color: #f7f7f7;
`

const BottomContent = styled.div<{
  hiddenMobile?: boolean
  hiddenDesktop?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  ${(props) => props.hiddenMobile && props.theme.breakpoints.down("md")} {
    display: none;
  }

  ${(props) => props.hiddenDesktop && props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 72px;
  }
`

const StyledFlexRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 6px;
  justify-content: flex-end;
  font-weight: 400;
  width: auto;
  height: min-content;
  padding: 0;
  font-size: 14px;
  line-height: 20.16px;
  width: 24px;
  height: 24px;
  min-height: 0;
  min-width: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 16px;
    line-height: 23.04px;
    justify-content: space-between;
    gap: 3px;
  }
  :hover {
    text-decoration: none;
  }
`

const StyledAccordion = styled(Accordion)`
  /* padding-left: 16px; */
  box-shadow: none;
  border: none;
  ::before {
    content: none;
  }
`
const StyledAccordionSummary = styled(AccordionSummary)`
  cursor: auto !important;
  padding: 0;

  .MuiAccordionSummary-content {
    margin: 0;
  }
`

const StyledEmptyDiv = styled.div`
  height: 104px;
  width: 106px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 104px;
    width: 128px;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

const formatPrice = (number?: number, hideFriction?: boolean) => {
  if (!number) return "0"
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: hideFriction ? 0 : 2,
    maximumFractionDigits: hideFriction ? 0 : 2,
  }).format(number)
}
type Props = {
  apartment: Apartment
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  bmbyId?: number
  status?: any
  pageNum: number
}
function RentAccordion({ apartment, formSubjects, messages, bmbyId, status, pageNum }: Props) {
  const [expanded, setExpanded] = useState<boolean>(false)
  const t = useTranslations("AccordionDetails")
  const locale = useLocale()
  useEffect(() => {
    setExpanded(false)
  }, [pageNum])
  const open = (event: React.MouseEvent<HTMLButtonElement>) => {
    choosenProject("מידע נוסף", apartment?.propertyTitle)
    event.stopPropagation() // Prevent accordion toggle
    setExpanded(true)
  }
  const close = (event: React.MouseEvent<HTMLButtonElement>) => {
    choosenProject("סגירה", apartment?.propertyTitle)
    event.stopPropagation() // Prevent accordion toggle
    setExpanded(false)
  }
  const formattedPrice = formatPrice(apartment.price, true)
  return (
    <StyledAccordion disableGutters={true} expanded={expanded}>
      <StyledAccordionSummary>
        <ItemBox expanded={expanded}>
          <UpperBox>
            <Desktop>
              {apartment.propertyImage ? (
                <StyledImage
                  alt="apartmentImage"
                  src={getUmbracoMediaUrl(apartment.propertyImage || "")}
                  height={104}
                  width={128}
                />
              ) : (
                <StyledEmptyDiv />
              )}
            </Desktop>
            <Mobile>
              {apartment.propertyImage ? (
                <StyledImage
                  alt="apartmentImage"
                  src={getUmbracoMediaUrl(apartment.propertyImage || "")}
                  height={104}
                  width={106}
                />
              ) : (
                <StyledEmptyDiv />
              )}
            </Mobile>
            <ContentPart>
              <TopContent>
                <NewText
                  component={"span"}
                  desktopFontSize={16}
                  desktopLineHeight={"100%"}
                  mobileFontSize={14}
                  mobileLineHeight={"100%"}
                  desktopFontWeight={600}
                  color={sharedColors.black}
                >
                  {apartment.propertyModel}
                </NewText>
                <NewText
                  component={"span"}
                  desktopFontSize={14}
                  desktopLineHeight={"100%"}
                  mobileFontSize={12}
                  mobileLineHeight={"100%"}
                  desktopFontWeight={400}
                  color={sharedColors.black}
                >
                  {apartment.address}
                </NewText>
              </TopContent>
              <MiddleContent>
                <StyledFlex>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={16}
                    desktopLineHeight={"100%"}
                    mobileFontSize={14}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {apartment.numberOfRooms}
                  </NewText>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"22px"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {t("numberOfRooms")}
                  </NewText>
                </StyledFlex>
                <StyledFlex>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={16}
                    desktopLineHeight={"100%"}
                    mobileFontSize={14}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {apartment.floor}
                  </NewText>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"20px"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {t("floor")}
                  </NewText>
                </StyledFlex>
                <StyledFlex>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"100%"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {apartment.areaInSquareMeters}
                  </NewText>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"20px"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {t("sqm")}
                  </NewText>
                </StyledFlex>
                <StyledFlex>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={16}
                    desktopLineHeight={"100%"}
                    mobileFontSize={14}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {apartment.balconySquareMeter}
                  </NewText>
                  <NewText
                    flex={1}
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"20px"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {t("balconySqm")}
                  </NewText>
                </StyledFlex>
                <LargeDivider />
              </MiddleContent>
              <BottomContent hiddenMobile={true}>
                <StyledFlex>
                  <NewText
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"100%"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    desktopFontWeight={400}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {t("entryDate")}
                  </NewText>
                  {apartment.entryDate && (
                    <NewText
                      component={"p"}
                      desktopFontSize={16}
                      desktopLineHeight={"100%"}
                      mobileFontSize={14}
                      mobileLineHeight={"100%"}
                      desktopFontWeight={600}
                      color={sharedColors.black}
                      columnMobile={true}
                    >
                      {moment(apartment.entryDate) >= moment(new Date())
                        ? moment(apartment.entryDate).format("DD/MM/YYYY")
                        : t("immediateEntry")}
                    </NewText>
                  )}
                </StyledFlex>
                <LargeDivider />
                <StyledFlex>
                  <NewText
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"100%"}
                    mobileFontSize={12}
                    mobileLineHeight={"100%"}
                    desktopFontWeight={400}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {t("rent")}
                  </NewText>
                  <NewText
                    component={"p"}
                    desktopFontSize={16}
                    desktopLineHeight={"100%"}
                    mobileFontSize={14}
                    mobileLineHeight={"100%"}
                    desktopFontWeight={600}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {formattedPrice} {apartment.priceCurrency}
                  </NewText>
                </StyledFlex>
                {/* <ExpansionPanel */}

                {expanded ? (
                  <StyledButton variant="text" onClick={close}>
                    <CircleXIcon color="black" />
                  </StyledButton>
                ) : (
                  <StyledButton variant="text" onClick={open}>
                    {locale === "he" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                  </StyledButton>
                )}
              </BottomContent>
            </ContentPart>
          </UpperBox>
          <BottomContent hiddenDesktop={true}>
            <StyledFlexRow>
              <NewText
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                desktopFontWeight={400}
                color={sharedColors.black}
                columnMobile={true}
              >
                {t("entryDate")}
              </NewText>
              {apartment.entryDate && (
                <NewText
                  component={"p"}
                  desktopFontSize={16}
                  desktopLineHeight={"100%"}
                  mobileFontSize={14}
                  mobileLineHeight={"100%"}
                  desktopFontWeight={600}
                  color={sharedColors.black}
                  columnMobile={true}
                >
                  {moment(apartment.entryDate) > moment(new Date())
                    ? moment(apartment.entryDate).format("DD/MM/YYYY")
                    : t("immediateEntry")}
                </NewText>
              )}
            </StyledFlexRow>
            <LargeDivider />
            <StyledFlexRow>
              <NewText
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                desktopFontWeight={400}
                color={sharedColors.black}
                columnMobile={true}
              >
                {t("rent")}
              </NewText>
              <NewText
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                desktopFontWeight={600}
                color={sharedColors.black}
                columnMobile={true}
              >
                {formattedPrice} {apartment.priceCurrency}
              </NewText>
            </StyledFlexRow>
            {/* <ExpansionPanel */}
            {expanded ? (
              <StyledButton variant="text" onClick={close}>
                <CircleXIcon color="black" />
              </StyledButton>
            ) : (
              <StyledButton variant="text" onClick={open}>
                {locale === "he" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              </StyledButton>
            )}
          </BottomContent>
        </ItemBox>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <RentAccordionInner
          apartment={apartment}
          formSubjects={formSubjects}
          messages={messages}
          bmbyId={bmbyId}
          status={status}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default RentAccordion
