import styled from "@emotion/styled"
import Link from "next/link"
import { FileIcon2, LinkButton, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { buttonClick, extractProperty, getUmbracoPDFUrl } from "utils"

const StyledReportsWrapper = styled.div`
  box-shadow: 0px 4px 12px 0px #00000029;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`

const StyledLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 24px;
  }
  div {
    padding: 0px;
  }
`

const StyledTextDiv = styled.div`
  min-height: 80px;
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 120px;
  }
`

type Props = {
  data: any
  maintitle?: string
}

function ReportsWrapper({ data, maintitle }: Props) {
  const title = extractProperty(data, "title")
  const content = extractProperty(data, "content")
  const link = extractProperty(data, "link")
  const pdf = extractProperty(data, "pdf")

  return (
    <StyledReportsWrapper>
      <StyledTextDiv>
        <NewText
          component={"h3"}
          desktopFontSize={32}
          marginBottom={"8px"}
          desktopLineHeight={"36px"}
          desktopFontWeight={400}
          mobileFontSize={24}
          mobileLineHeight="28px"
          color={sharedColors.black}
        >
          {title}
        </NewText>
        {pdf.length === 0 && (
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight="17px"
            color={sharedColors.black}
          >
            {content}
          </NewText>
        )}

        {pdf && pdf.length > 0 && (
          <StyledLink
            href={getUmbracoPDFUrl(pdf[0]?.url) || ""}
            target="_blank"
            onClick={() => buttonClick(content, maintitle || "")}
          >
            <FileIcon2 width={20} height={20} />
            <NewText
              desktopFontSize={14}
              desktopFontWeight="400"
              desktopLineHeight="20px"
              mobileFontSize={12}
              style={{ borderBottom: "1px solid black" }}
              mobileFontWeight="400"
              mobileLineHeight={"17px"}
              component="p"
              color={sharedColors.black}
            >
              {content}
            </NewText>
          </StyledLink>
        )}
      </StyledTextDiv>
      <StyledLinksWrapper>
        <LinkButton link={link} mainTitle={maintitle} />
      </StyledLinksWrapper>
    </StyledReportsWrapper>
  )
}

export default ReportsWrapper
