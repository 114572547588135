/** THIS FILE IS AUTOMATICALLY GENERATED FROM : https://testapi.ashtrom.co.il//swagger/v1/swagger.json **/

import axios from "../axios"
import * as Ashtrom from "./AshtromInterfaces"

export const getWebBmbyProjectsProjectId = (
  projectId: string,
  locale?: string
) =>
  axios.get<Ashtrom.ProjectDtoResponseWrapper>(
    `/web/bmby/projects/${projectId}`,
    { headers: { "X-Accept-Language": locale } }
  )

export const getWebBmbyAllProjects = (locale?: string) =>
  axios.get<Ashtrom.ProjectDtoListResponseWrapper>(`/web/bmby/allProjects`, {
    headers: { "X-Accept-Language": locale },
  })

export const getWebBmbyVersion = (locale?: string) =>
  axios.get<Ashtrom.StringResponseWrapper>(`/web/bmby/version`, {
    headers: { "X-Accept-Language": locale },
  })

export const postWebBmbyLead = (data: Ashtrom.LeadRequest, locale?: string) =>
  axios.post<Ashtrom.SendLeadResponseResponseWrapper>(`/web/bmby/lead`, data, {
    headers: { "X-Accept-Language": locale },
  })

export const getWebCareerJobsOrderId = (orderId: string, locale?: string) =>
  axios.get<Ashtrom.JobDtoResponseWrapper>(`/web/career/jobs/${orderId}`, {
    headers: { "X-Accept-Language": locale },
  })

export const postWebCareerJobsLobby = (
  data: Ashtrom.JobRequest,
  locale?: string
) =>
  axios.post<Ashtrom.JobsLobbyResponseResponseWrapper>(
    `/web/career/jobsLobby`,
    data,
    { headers: { "X-Accept-Language": locale } }
  )

export const postWebCareerApply = (
  data: Ashtrom.ApplyRequest,
  locale?: string
) =>
  axios.post<Ashtrom.StringResponseWrapper>(`/web/career/apply`, data, {
    headers: { "X-Accept-Language": locale },
  })

export const getWebContactUsSubjectsCompanyId = (
  companyId: string,
  locale?: string
) =>
  axios.get<Ashtrom.Int32StringDictionaryResponseWrapper>(
    `/web/contactUs/subjects/${companyId}`,
    { headers: { "X-Accept-Language": locale } }
  )

export const postWebContactUsSendContactUs = (
  data: Ashtrom.SendContactUsFormRequest,
  locale?: string
) =>
  axios.post<Ashtrom.BooleanResponseWrapper>(
    `/web/contactUs/sendContactUs`,
    data,
    { headers: { "X-Accept-Language": locale } }
  )

export const postWebContactUsSendDonationRequest = (
  formData: FormData,
  onUploadProgress?: (progressEvent: any) => void,
  locale?: string
) =>
  axios.post<Ashtrom.BooleanResponseWrapper>(
    `/web/contactUs/sendDonationRequest`,
    formData,
    {
      onUploadProgress,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Accept-Language": locale,
      },
    }
  )

export const getWebPriorityProjectsProjectId = (
  projectId: string,
  locale?: string
) =>
  axios.get<Ashtrom.ProjectDtoResponseWrapper>(
    `/web/priority/projects/${projectId}`,
    { headers: { "X-Accept-Language": locale } }
  )

export const getWebPriorityAllEntitledProjects = (locale?: string) =>
  axios.get<Ashtrom.ProjectDtoListResponseWrapper>(
    `/web/priority/allEntitledProjects`,
    { headers: { "X-Accept-Language": locale } }
  )

export const getWebPriorityAllFreeMarketProjects = (locale?: string) =>
  axios.get<Ashtrom.ProjectDtoListResponseWrapper>(
    `/web/priority/allFreeMarketProjects`,
    { headers: { "X-Accept-Language": locale } }
  )

export const getWebTaseStockBanner = (locale?: string) =>
  axios.get<Ashtrom.SlimStockDtoResponseWrapper>(`/web/tase/stockBanner`, {
    headers: { "X-Accept-Language": locale },
  })

export const getWebTaseGraphStock = (locale?: string) =>
  axios.get<Ashtrom.GraphStockResponseResponseWrapper>(`/web/tase/graphStock`, {
    headers: { "X-Accept-Language": locale },
  })

export const getWebTaseBondsStockType = (StockType: string, locale?: string) =>
  axios.get<Ashtrom.BondsTableResponseResponseWrapper>(
    `/web/tase/bonds/${StockType}`,
    { headers: { "X-Accept-Language": locale } }
  )

export const getWebTaseStockHolders = (locale?: string) =>
  axios.get<Ashtrom.StockHoldersResponseResponseWrapper>(
    `/web/tase/stockHolders`,
    { headers: { "X-Accept-Language": locale } }
  )
