import styled from "@emotion/styled"
import { Button, FormHelperText } from "@mui/material"
import { useLocale } from "next-intl"
import { FieldError, UseFormSetValue, UseFormTrigger } from "react-hook-form"
import { NewText } from "ui"
import { CheckMark2Icon, CircleXIcon, FileIcon } from "ui/icons"
import { sharedColors as colors } from "ui/theme/colors"
import { FileState, FormInput } from "./FormComponent"

const StyledUplaodWrapper = styled.div<{ status: boolean }>`
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 1px dashed ${(props) => (props.status ? "#c92418" : "black")};
  border-radius: 4px;
  /* min-height: 58.88px; */
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  padding-right: 16px;
  padding-left: 16px;
  flex-wrap: wrap;
  height: 100%;
  input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  width: 100%;
`
const StyledFileName = styled.div`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 150px;
  }
`
const StyledButton = styled(Button)`
  color: ${colors.blue};
  padding: 0px;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 18px;
  color: black;
  text-decoration: none;
  height: 100%;
  font-weight: 400;
  :hover {
    text-decoration: none;
  }
  svg {
    margin-right: 8px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 24px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 16px;
  }
`
const StyledDiv = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: relative;
  }
`
const StyledHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: boolean }>`
  margin-top: 15px;
  margin-bottom: 16px;
  position: static;
  color: ${(props) => (props.status ? "#C92418" : "#008015")};
  font-size: 12px;
`

const StyledTextResponse = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

type FileUploadProps = {
  setFileState: React.Dispatch<React.SetStateAction<FileState>>
  fileState: FileState
  isSubmitted: boolean
  setProgress: React.Dispatch<React.SetStateAction<boolean>>
  setValue: UseFormSetValue<FormInput>
  formError?: FieldError
  trigger: UseFormTrigger<FormInput>
  attach?: string
}

const FileUpload: React.FC<FileUploadProps> = ({
  fileState,
  setFileState,
  isSubmitted,
  setValue,
  formError,
  trigger,
  attach,
}) => {
  const locale = useLocale()
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null
    if (selectedFile) {
      const allowedFormats = ["rtf", "txt", "doc", "docx", "pdf"]
      const maxSizeInBytes = 3 * 1024 * 1024 // 3MB
      const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase()
      const fileSize = selectedFile.size
      setFileState((prevState) => ({
        ...prevState,
        fileName: selectedFile.name,
        fileSize: selectedFile.size,
      }))
      setValue("fileName", selectedFile.name)
      trigger("fileName")
      if (!allowedFormats.includes(fileExtension || "") || fileSize > maxSizeInBytes) {
        setFileState((prevState) => ({
          ...prevState,
          fileError: "הקובץ חורג מ 3 מגה או אינו בפורמט: rtf, txt, doc, docx, pdf.",
        }))

        return
      }

      const reader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          const base64String = btoa(e.target.result as string)
          setFileState((prevState) => ({ ...prevState, file: base64String }))
        }
      }
      reader.readAsBinaryString(selectedFile)
      setFileState((prevState) => ({ ...prevState, fileError: "" }))
    }
  }
  const error = !!fileState.fileError || !!formError?.message
  return (
    <StyledDiv>
      <StyledUplaodWrapper status={error}>
        <StyledButton>
          {fileState.fileName ? (
            <>
              <CircleXIcon color="black" />
              <StyledFileName>{fileState.fileName}</StyledFileName>
            </>
          ) : (
            <>
              <FileIcon />
              {attach ? attach : "צירוף קובץ קו״ח"}
            </>
          )}
        </StyledButton>
        <input
          type="file"
          onChange={handleFileInputChange}
          accept=".rtf, .txt, .doc, .docx, .pdf"
        />
      </StyledUplaodWrapper>

      <StyledHelperText status={error}>
        {error || isSubmitted ? (
          <>
            <StyledTextResponse>
              <CheckMark2Icon state={!error} />
              <span>
                {error && fileState.fileError ? fileState.fileError : null}
                {error && formError?.message ? formError?.message : null}
                {!error ? "הושלם" : null}
              </span>
            </StyledTextResponse>
          </>
        ) : null}
      </StyledHelperText>
      <NewText
        component={"p"}
        desktopFontSize={14}
        desktopLineHeight={"20px"}
        desktopFontWeight={400}
        color={colors.black}
        mobileFontSize={12}
        style={{ position: "absolute", bottom: "-20px" }}
      >
        {locale === "he" ? "ניתן להעלות קובץ עד 1.5 MB" : "You can upload a file up to 1.5 MB"}
      </NewText>
    </StyledDiv>
  )
}

export default FileUpload
