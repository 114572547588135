import styled from "@emotion/styled"
import { LobbyAvailableFilters } from "api"
import { Desktop, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { JobsObject } from "utils"
import { CareerLobbyMessages } from "../Careers"
import JobCard from "./JobCard"
import JobSkeleton from "./JobSkeleton"

const TextBox = styled.div`
  display: flex;
  gap: 16px;
  height: 20px;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: flex-start;
  }
`
export const Divider = styled.div`
  width: 1px; /* or height for horizontal dividers */
  background-color: #333; /* Divider color */
  height: 20px;
  border-radius: 8px;
`
const JobsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`
const JobCards = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  row-gap: 24px;
  column-gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
  }
`

type Props = {
  jobs: JobsObject["items"]
  isFetching: boolean
  isFetchingNextPage: boolean
  locale: string
  messages: CareerLobbyMessages
  totalCount: number
  filters: LobbyAvailableFilters
}
function JobsDisplay({
  jobs,
  isFetching,
  isFetchingNextPage,
  locale,
  messages,
  totalCount,
  filters,
}: Props) {
  const numberText = messages.viewResults.includes("№")
    ? `${messages.viewResults.replace("№", String(totalCount))}`
    : `${totalCount} ${messages.viewResults}`

  return (
    <JobsBox>
      {jobs.length > 0 ? (
        <TextBox>
          <NewText
            component={"span"}
            desktopFontSize={16}
            color={colors.black}
            desktopFontWeight={600}
            mobileFontSize={14}
          >
            {messages.jobsBoard}
          </NewText>
          <Desktop>
            <Divider />
          </Desktop>
          <NewText component={"span"} desktopFontSize={16} color={colors.black} mobileFontSize={14}>
            {totalCount && numberText}
          </NewText>
        </TextBox>
      ) : (
        <NewText
          component={"span"}
          desktopFontSize={18}
          mobileFontSize={16}
          color={colors.black}
          desktopFontWeight={600}
        >
          לא נמצאו תוצאות
        </NewText>
      )}

      <JobCards>
        {isFetching && !isFetchingNextPage ? (
          <>
            {[...Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </>
        ) : null}
        {!isFetching || (isFetching && isFetchingNextPage)
          ? jobs.map((el, index) => (
              <JobCard
                key={el?.orderId || index}
                job={el}
                locale={locale}
                messages={messages}
                filters={filters}
              />
            ))
          : null}

        {isFetchingNextPage ? (
          <>
            {[...Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </>
        ) : null}
      </JobCards>
    </JobsBox>
  )
}

export default JobsDisplay
