"use client"

import styled from "@emotion/styled"
import Link from "next/link"
import { useState } from "react"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container, Desktop, Mobile, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty, findProperty } from "utils"

type Props = {
  data: any
}

const StyledSlideDiv = styled.div`
  width: 100%;
  background-color: #e0f3ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  align-items: center;
  min-height: 533px;
  padding-bottom: 16px;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    height: 240px;
    min-height: 240px;
    padding-left: 16px;
    padding-bottom: 0px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  border: 2px solid black;
  border-radius: 200px;
  padding: 16px;
  width: fit-content;
  height: 48px;
  align-items: center;
  margin-top: 16px;
`

const StyledTextwrapp = styled.div`
  max-width: 600px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 8px;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  width: 400px;
  height: 328px;
  right: -1px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;
  }
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
  position: absolute;
  top: 340px;
  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
  }
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  border: ${(props) => (props.active ? "1px solid black" : "1px solid black")};
  background-color: ${(props) => (props.active ? "black" : "none")};
`

function MarketingBanner({ data }: Props) {
  const block = findProperty(data.page, "blocks").blocks
  const apartmentsSalesSlide = block.filter(
    (block: any) => block.blockType === "apartmentsSalesSlide",
  )[0]
  const slideElements = extractProperty(apartmentsSalesSlide, "slideElements")

  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [mobileSwiperRef, setMobileSwiperRef] = useState<any>(null)
  const [mobileActiveIndex, setMobileActiveIndex] = useState<number>(0)

  return (
    <>
      <Desktop>
        <Container>
          <Swiper
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex)
            }}
            slidesPerView={1}
            freeMode={true}
            onSwiper={setSwiperRef}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            {slideElements.map((slide: any, index: number) => {
              const bannerImageDescktop = extractImage(slide, "bannerImageDescktop")

              const title = extractProperty(slide, "title")
              const text = extractProperty(slide, "text")
              const linkForMore = extractProperty(slide, "linkForMore")
              return (
                <SwiperSlide key={index.toString()}>
                  <StyledSlideDiv>
                    <StyledTextwrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={32}
                        desktopLineHeight={"36px"}
                        desktopFontWeight={400}
                        mobileFontSize={18}
                        mobileLineHeight={"26px"}
                        mobileFontWeight={600}
                        color={sharedColors.black}
                      >
                        {title}
                      </NewText>
                      <NewText
                        component={"p"}
                        desktopFontSize={18}
                        desktopLineHeight={"26px"}
                        desktopFontWeight={400}
                        mobileFontSize={16}
                        mobileLineHeight={"23px"}
                        mobileFontWeight={400}
                        color={sharedColors.black}
                        marginTop={"8px"}
                      >
                        {text}
                      </NewText>
                      <StyledLink href={linkForMore[0].url} target={linkForMore[0].target}>
                        <NewText
                          component={"p"}
                          desktopFontSize={16}
                          desktopLineHeight={"23px"}
                          desktopFontWeight={600}
                          mobileFontSize={14}
                          mobileLineHeight={"20px"}
                          mobileFontWeight={600}
                          color={sharedColors.black}
                        >
                          {linkForMore[0].name}
                        </NewText>
                      </StyledLink>
                    </StyledTextwrapp>

                    <StyledImageWrapper>
                      <ImageWithAlt
                        image={bannerImageDescktop}
                        layout="fill"
                        style={{ objectFit: "cover" }}
                      />
                    </StyledImageWrapper>
                    <StyledBulletsContainer>
                      {slideElements.map((_: any, index: number) => (
                        <StyledBullet
                          key={index.toString()}
                          onClick={() => {
                            swiperRef.slideTo(index)
                          }}
                          active={index === activeIndex}
                        />
                      ))}
                    </StyledBulletsContainer>
                  </StyledSlideDiv>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Container>
      </Desktop>
      <Mobile>
        <Container>
          <Swiper
            onSlideChange={(swiper) => {
              setMobileActiveIndex(swiper.activeIndex)
            }}
            slidesPerView={1}
            freeMode={true}
            onSwiper={setMobileSwiperRef}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            {slideElements.map((slide: any, index: number) => {
              const bannerImageForMobile = extractImage(slide, "bannerImageForMobile")
              const title = extractProperty(slide, "title")
              const text = extractProperty(slide, "text")
              const linkForMore = extractProperty(slide, "linkForMore")
              return (
                <SwiperSlide key={index.toString()}>
                  <StyledSlideDiv>
                    <StyledTextwrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={32}
                        desktopLineHeight={"36px"}
                        desktopFontWeight={400}
                        mobileFontSize={18}
                        mobileLineHeight={"26px"}
                        mobileFontWeight={600}
                        color={sharedColors.black}
                      >
                        {title}
                      </NewText>
                      <NewText
                        component={"p"}
                        desktopFontSize={18}
                        desktopLineHeight={"26px"}
                        desktopFontWeight={400}
                        mobileFontSize={16}
                        mobileLineHeight={"23px"}
                        mobileFontWeight={400}
                        color={sharedColors.black}
                        marginTop={"8px"}
                      >
                        {text}
                      </NewText>
                      <StyledLink href={linkForMore[0].url} target={linkForMore[0].target}>
                        <NewText
                          component={"p"}
                          desktopFontSize={16}
                          desktopLineHeight={"23px"}
                          desktopFontWeight={600}
                          mobileFontSize={14}
                          mobileLineHeight={"20px"}
                          mobileFontWeight={600}
                          color={sharedColors.black}
                        >
                          {linkForMore[0].name}
                        </NewText>
                      </StyledLink>
                    </StyledTextwrapp>

                    <StyledImageWrapper>
                      <ImageWithAlt
                        image={bannerImageForMobile}
                        fill
                        style={{ objectFit: "contain" }}
                      />
                    </StyledImageWrapper>
                    <StyledBulletsContainer>
                      {slideElements.map((_: any, index: number) => (
                        <StyledBullet
                          key={index.toString()}
                          onClick={() => {
                            mobileSwiperRef.slideTo(index)
                          }}
                          active={index === mobileActiveIndex}
                        />
                      ))}
                    </StyledBulletsContainer>
                  </StyledSlideDiv>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Container>
      </Mobile>
    </>
  )
}

export default MarketingBanner
