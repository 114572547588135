import styled from "@emotion/styled"
import { useTranslations } from "next-intl"
import { NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { ApartmentUmbraco } from "utils/projects"

const StyledPlanDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 24px;
  }
`

const StyledBluePrintDiv = styled.div`
  height: 280px;
  background-color: white;
  border-radius: 16px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 444px;
    width: 65%;
  }
`

const StyledMoreInfo = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 35%;
  }
`

const StyledValueDiv = styled.div`
  min-height: 39px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f7f7f7;
  &:last-child {
    border-bottom: none;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 42px;
  }
`

type Props = {
  apartment: ApartmentUmbraco
}

function ApartmentPlan({ apartment }: Props) {
  const t = useTranslations("AccordionDetails")

  return (
    <StyledPlanDiv>
      <StyledBluePrintDiv></StyledBluePrintDiv>
      <StyledMoreInfo>
        <NewText
          component={"p"}
          desktopFontSize={16}
          desktopLineHeight={"23px"}
          desktopFontWeight={600}
          mobileFontSize={14}
          mobileLineHeight={"20px"}
          color={sharedColors.black}
        >
          {t("moreInformation")}
        </NewText>
        <StyledValueDiv>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {t("building")}
          </NewText>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {apartment.buildingTitle}
          </NewText>
        </StyledValueDiv>
        <StyledValueDiv>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {t("apartmentNumber")}
          </NewText>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {apartment.propertyTitle}
          </NewText>
        </StyledValueDiv>
        <StyledValueDiv>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {t("direction")}
          </NewText>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {apartment.orientation}
          </NewText>
        </StyledValueDiv>
        <StyledValueDiv>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {t("boardfee")}
          </NewText>
        </StyledValueDiv>
      </StyledMoreInfo>
    </StyledPlanDiv>
  )
}

export default ApartmentPlan
