/* eslint-disable quotes */
"use client"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import { Fragment, useMemo } from "react"
import { Autoplay } from "swiper"

import "swiper/css"
import "swiper/css/autoplay"
import { Swiper, SwiperSlide } from "swiper/react"

import { NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"

const formatNumber = (number: number) => {
  return new Intl.NumberFormat("en-US").format(number)
}

const StyledSlide = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const StyledLine = styled.div`
  height: 26px;
  width: 1px;
  background-color: #6f6f6f;
  margin-left: 16px;
  margin-right: 16px;
`

const StyledDiv = styled(Swiper)`
  padding: 0px;
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  height: 38px;
  border-top: 1px solid #6f6f6f;
  background-color: white;
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 98px;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
  }
`

const StyledSlider = styled.div``

type Props = {
  stockBanner: any
  change: string
  lasttransaction: string
  StockRate: string
  cents: string
  baseRate: string
}

function StockBanner({
  stockBanner,
  change,

  StockRate,
  cents,
  baseRate,
}: Props) {
  const LOOP_LENGHT = 100
  const locale = useLocale()
  const loopArray = useMemo(() => {
    const originalArray = new Array(stockBanner)
    let duplicatedArray = [...originalArray]
    try {
      while (duplicatedArray.length < LOOP_LENGHT) {
        duplicatedArray = [...duplicatedArray, ...originalArray]
      }
    } catch (error) {
      console.error(error)
    }

    return duplicatedArray
  }, [stockBanner])

  return (
    <StyledSlider>
      <StyledDiv
        loop={true}
        modules={[Autoplay]}
        slidesPerView={"auto"}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        speed={6000}
      >
        {loopArray.map((item, index) => (
          <Fragment key={index.toString()}>
            <SwiperSlide key={`${index}_a`}>
              <StyledSlide>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                  marginRight={"8px"}
                >
                  {`${StockRate}: ${item?.symbol}`}
                </NewText>

                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                >
                  {`${formatNumber(item?.securityLastRate)} ${cents}`}
                </NewText>
                <StyledLine />
              </StyledSlide>
            </SwiperSlide>
            <SwiperSlide key={`${index}_b`}>
              <StyledSlide>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                  marginRight={"8px"}
                >
                  {change}:
                </NewText>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={item?.changeValue > 0 ? "#008015" : colors.red}
                  style={{ unicodeBidi: "plaintext" }}
                  marginRight={"8px"}
                >
                  {item?.changeValue}
                </NewText>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={item?.changeValue > 0 ? "#008015" : colors.red}
                  style={{ unicodeBidi: "plaintext" }}
                  marginRight={"8px"}
                >
                  {" "}
                  {item?.securityPercentageChange}%
                </NewText>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                  marginRight={"8px"}
                >
                  {`/`}
                </NewText>

                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                  marginRight={"8px"}
                >
                  {`${locale === "he" ? 'מחזור ע"נ' : "NV turnover"}`}{" "}
                </NewText>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                >
                  {formatNumber(item?.securityDailyAggVolume)}
                </NewText>
                <StyledLine />
              </StyledSlide>
            </SwiperSlide>
            <SwiperSlide key={`${index}_c`}>
              <StyledSlide>
                <NewText
                  component={"p"}
                  desktopFontSize={18}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={colors.black}
                >
                  {baseRate}: {formatNumber(item?.basePrice)}
                </NewText>
                <StyledLine />
              </StyledSlide>
            </SwiperSlide>
          </Fragment>
        ))}
      </StyledDiv>
    </StyledSlider>
  )
}

export default StockBanner
