import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Link from "next/link"
import { CircleEllipse, Container, NewText, useIsDesktop } from "ui"
import { sharedColors } from "ui/theme/colors"
import { apartmentPageStrip } from "utils/gtm"
import { LotteryType } from "utils/projects"

const StyledCont = styled.div`
  position: relative;
  margin-top: 32px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  background-color: ${sharedColors.oldBrandBlue};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 32px;
    margin-top: 60px;
    border-radius: 16px;
  }
`
const TopText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 8px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    p {
      margin: 0px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: center;
    width: 70%;
    gap: 16px;
  }
`
const StyledLink = styled(Link)`
  ${(props) => props.theme.breakpoints.down("md")} {
    align-self: end;
  }
`
const StyledButton = styled(Button)`
  border-color: white;
  color: white;

  :hover {
    background-color: white;
    color: black;
  }
  :active {
    border-color: white;
  }
`
const StyledBackTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
const StyledBackBottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

type Props = {
  lottery: LotteryType
}

function CTA({ lottery }: Props) {
  const isDesktop = useIsDesktop()
  return (
    <Container withoutPadding={isDesktop ? false : true}>
      <StyledCont>
        <TopText>
          <NewText
            component={"h3"}
            desktopFontSize={32}
            desktopLineHeight={"36px"}
            mobileFontSize={24}
            mobileLineHeight={"28px"}
            color={sharedColors.white}
          >
            {lottery.boxTitle}
          </NewText>
          <NewText
            component={"p"}
            desktopFontSize={18}
            desktopLineHeight={"26px"}
            mobileFontSize={16}
            mobileLineHeight={"23px"}
            color={sharedColors.white}
            dangerouslySetInnerHTML={{ __html: lottery.boxTextHTML || "" }}
          />
        </TopText>
        <StyledLink target="_blank" href={lottery.button?.url || ""}>
          <StyledButton
            variant="outlined"
            size="large"
            style={{ fontSize: 20 }}
            onClick={() => apartmentPageStrip(lottery.button?.name, lottery.boxTitle)}
          >
            {lottery.button?.name}
          </StyledButton>
        </StyledLink>
        <StyledBackTop>
          <CircleEllipse position="top" />
        </StyledBackTop>
        <StyledBackBottom>
          <CircleEllipse position="bottom" />
        </StyledBackBottom>
      </StyledCont>
    </Container>
  )
}

export default CTA
