"use client"

import styled from "@emotion/styled"
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material"
import { useTranslations } from "next-intl"
import Image from "next/image"
import { useState } from "react"
import { ArrowDown, CircleXIcon, Desktop, Mobile, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { getUmbracoMediaUrl } from "utils"
import { choosenProject } from "utils/gtm"
import { ApartmentUmbraco } from "utils/projects"
import { ProjectsMessages } from "../../Projects"
import AccordionInner from "./AccordionInner"

const ItemBox = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: stretch;
  gap: 24px;
  height: 120px;
  width: 100%;
  padding: 8px;
  border-radius: 16px;
  border-bottom-left-radius: ${({ expanded }) => (expanded ? "0" : "16")}px;
  border-bottom-right-radius: ${({ expanded }) => (expanded ? "0" : "16")}px;
  box-shadow: 0px 4px 12px 0px #00000029;
  ${(props) => props.theme.breakpoints.down("md")} {
    height: auto;
    min-height: 120px;
  }
`

const StyledImage = styled(Image)`
  border-radius: 8px;
  overflow: hidden;
`

const ContentPart = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: end;
    flex-direction: row;
    padding: 8px;
  }
`

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-self: baseline;
  margin-inline-end: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  //background-color: #ee0808;
  flex: 0.97;
  //width: 65%;
  ${(props) => props.theme.breakpoints.up("md")} {
    border-inline-start: 2px solid #f7f7f7;
    border-inline-end: 2px solid #f7f7f7;
    padding: 0 17.83px;
  }
`

const SmallDivider = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
  height: 66px;
  width: 2px;
  background-color: #f7f7f7;
`
// const LargeDivider = styled.div`
//   display: none;
//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
//   height: 100%;
//   width: 2px;
//   background-color: #f7f7f7;
// `
const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0 17.83px;
  }
`
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 6px;
  justify-content: flex-end;
  font-weight: 400;
  width: auto;
  height: min-content;
  padding: 0;
  font-size: 14px;
  line-height: 20.16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 16px;
    line-height: 23.04px;
    justify-content: space-between;
    gap: 3px;
  }
  :hover {
    text-decoration: none;
  }
`

const StyledAccordion = styled(Accordion)`
  /* padding-left: 16px; */
  box-shadow: none;
  border: none;
  ::before {
    content: none;
  }
`
const StyledAccordionSummary = styled(AccordionSummary)`
  cursor: auto !important;
  padding: 0;

  .MuiAccordionSummary-content {
    margin: 0;
  }
`

const StyledEmptyDiv = styled.div`
  height: 104px;
  width: 106px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 104px;
    width: 128px;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

const formatPrice = (number?: number, hideFriction?: boolean) => {
  if (!number) return "0"
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: hideFriction ? 0 : 2,
    maximumFractionDigits: hideFriction ? 0 : 2,
  }).format(number)
}

type Props = {
  apartment: ApartmentUmbraco
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  bmbyId?: number
  status?: any
  leadFormBMBYId?: any
}
function AccordionItem({
  apartment,
  formSubjects,
  messages,
  bmbyId,
  status,
  leadFormBMBYId,
}: Props) {
  const [expanded, setExpanded] = useState<boolean>(false)
  const t = useTranslations("AccordionDetails")
  const open = (event: React.MouseEvent<HTMLButtonElement>) => {
    choosenProject("מידע נוסף", apartment?.propertyName)
    event.stopPropagation() // Prevent accordion toggle
    setExpanded(true)
  }
  const close = (event: React.MouseEvent<HTMLButtonElement>) => {
    choosenProject("סגירה", apartment?.propertyName)
    event.stopPropagation() // Prevent accordion toggle
    setExpanded(false)
  }
  const formattedPrice = formatPrice(apartment.price, true)
  return (
    <StyledAccordion disableGutters={true} expanded={expanded}>
      <StyledAccordionSummary>
        <ItemBox expanded={expanded}>
          <Desktop>
            {apartment.propertyImage ? (
              <StyledImage
                alt="apartmentImage"
                src={getUmbracoMediaUrl(apartment.propertyImage || "")}
                height={104}
                width={128}
              />
            ) : (
              <StyledEmptyDiv />
            )}
          </Desktop>
          <Mobile>
            {apartment.propertyImage ? (
              <StyledImage
                alt="apartmentImage"
                src={getUmbracoMediaUrl(apartment.propertyImage || "")}
                height={104}
                width={106}
              />
            ) : (
              <StyledEmptyDiv />
            )}
          </Mobile>
          <ContentPart>
            <TopContent>
              <NewText
                component={"span"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                desktopFontWeight={600}
                color={sharedColors.black}
              >
                {apartment.propertyName}
              </NewText>
            </TopContent>
            <MiddleContent>
              <NewText
                flex={1}
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                color={sharedColors.black}
                columnMobile={true}
              >
                {apartment.numberOfRooms} {t("numberOfRooms")}
              </NewText>
              <SmallDivider />
              <NewText
                flex={1}
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                color={sharedColors.black}
                columnMobile={true}
              >
                {apartment.floor} {t("floor")}
              </NewText>
              <SmallDivider />
              <NewText
                flex={1}
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                color={sharedColors.black}
                columnMobile={true}
              >
                {apartment.areaInSquareMeters} {t("sqm")}
              </NewText>
              <SmallDivider />
              <NewText
                flex={1}
                component={"p"}
                desktopFontSize={14}
                desktopLineHeight={"20px"}
                mobileFontSize={12}
                mobileLineHeight={"17px"}
                color={sharedColors.black}
                columnMobile={true}
              >
                {apartment.balconySquareMeter} {t("balconySqm")}
              </NewText>
              {apartment.price && (
                <>
                  <SmallDivider />
                  <NewText
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"20px"}
                    mobileFontSize={12}
                    mobileLineHeight={"17px"}
                    desktopFontWeight={600}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    {formattedPrice} {apartment.priceCurrency}
                  </NewText>
                </>
              )}
            </MiddleContent>
            <BottomContent>
              {/* <ExpansionPanel */}
              {expanded ? (
                <StyledButton variant="text" onClick={close}>
                  <span>{t("close")}</span> <CircleXIcon color="black" />
                </StyledButton>
              ) : (
                <StyledButton variant="text" onClick={open}>
                  {t("moreInfo")}
                  <ArrowDown />
                </StyledButton>
              )}
            </BottomContent>
          </ContentPart>
        </ItemBox>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <AccordionInner
          apartment={apartment}
          formSubjects={formSubjects}
          messages={messages}
          bmbyId={bmbyId}
          status={status}
          leadFormBMBYId={leadFormBMBYId}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default AccordionItem
