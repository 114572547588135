"use client"

import styled from "@emotion/styled"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { Container, ContractIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { getFileName } from "./ApartmentDocs"

const StyledContainer = styled.div`
  padding-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
  }
`

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  border: 1px ${sharedColors.oldBrandBlue} solid;
`
const HeaderContainer = styled.div`
  display: flex;
  padding: 16px;

  background-color: ${sharedColors.oldBrandBlue};
`

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  row-gap: 16px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 40px;
    row-gap: 24px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 40px; // Match the top padding
      bottom: 40px; // Match the bottom padding
      width: 2px;
      background-color: var(--Light-grey, #f7f7f7);
    }

    &::before {
      left: calc((100% - 80px) / 3 + 40px - 11px); // First column divider
    }

    &::after {
      left: calc((100% - 80px) * 2 / 3 + 40px - 11px); // Second column divider
    }
  }
`

const DocContainer = styled.div`
  /* height: 52px; */
  display: flex;
  gap: 8px;
  align-items: center;
`

const StyledLink = styled(Link)`
  text-decoration-color: black;
`
export default function EntitledDocs({ docs }: { docs: string[] }) {
  const t = useTranslations("AccordionDetails")
  if (!docs.length) {
    return null
  }
  return (
    <Container>
      <StyledContainer>
        <StyledInnerContainer>
          <HeaderContainer>
            <NewText
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              mobileFontSize={24}
              mobileLineHeight={"100%"}
              component={"h2"}
              color="white"
            >
              {t("agreementDocs")}
            </NewText>
          </HeaderContainer>
          <div>
            <BodyContainer>
              {docs.map((el, i) => (
                <DocContainer key={i}>
                  <ContractIcon />
                  <StyledLink href={el} target="_blank">
                    <NewText component={"span"} desktopFontSize={14} color="black">
                      {getFileName(el)}
                    </NewText>
                  </StyledLink>
                </DocContainer>
              ))}
            </BodyContainer>
          </div>
        </StyledInnerContainer>
      </StyledContainer>
    </Container>
  )
}
