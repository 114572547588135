import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { Container, LeftArrow, NewText, RightArrow } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors } from "ui/theme/colors"
import { extractImage, extractProperty, getNonUmbracoUrl, getUmbracoPDFUrl } from "utils"

const StyledDiv = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

const StyledProjectDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const StyledProjectItem = styled.div`
  height: 260px;
  a {
    text-decoration: none;
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
`

const StyledWhiteOverlay = styled.div`
  min-height: 65px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.88);
  padding: 8px;
`
const StyledTagDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.88);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
`

const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const StyledPdfLink = styled(Link)`
  text-decoration: underline !important;
  padding-top: 8px;
  display: flex;
  text-decoration-color: #2768a3 !important;
`
type Props = {
  data: any
}

const EligibleProjects = ({ data }: Props) => {
  const blockTitle = extractProperty(data, "blockTitle")
  const projectList = extractProperty(data, "projectList")
  const locale = useLocale()
  const handleInnerLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()
  }
  return (
    <Container>
      <StyledDiv>
        <NewText
          component={"h2"}
          desktopFontSize={32}
          mobileFontSize={24}
          desktopLineHeight={1.2}
          desktopFontWeight={400}
          color={sharedColors.black}
        >
          {blockTitle}
        </NewText>
        <StyledProjectDiv>
          {projectList.map((item: any, index: number) => {
            const image = extractImage(item, "image")
            const tag = extractProperty(item, "tag")
            const linkToProjectPage = extractProperty(item, "linkToProjectPage")
            const linkToPdfFile = extractProperty(item, "linkToPdfFile")
            return (
              <StyledProjectItem key={index.toString()}>
                <Link href={getNonUmbracoUrl(linkToProjectPage[0]?.url)}>
                  <StyledImageWrapper>
                    <ImageWithAlt image={image} fill style={{ objectFit: "cover" }} />
                    {tag && (
                      <StyledTagDiv>
                        <NewText
                          component={"p"}
                          desktopFontSize={16}
                          desktopFontWeight={400}
                          color={sharedColors.black}
                        >
                          {tag}
                        </NewText>
                      </StyledTagDiv>
                    )}

                    <StyledWhiteOverlay>
                      <StyledLinkWrapper>
                        <NewText
                          component={"p"}
                          desktopFontSize={14}
                          desktopFontWeight={600}
                          color={sharedColors.black}
                        >
                          {" "}
                          {linkToProjectPage[0]?.name}
                        </NewText>
                        {locale === "he" ? <LeftArrow /> : <RightArrow />}
                      </StyledLinkWrapper>
                      {linkToPdfFile && linkToPdfFile.length > 0 && (
                        <StyledPdfLink
                          href={getUmbracoPDFUrl(linkToPdfFile[0]?.url)}
                          target="_blank"
                          onClick={handleInnerLinkClick}
                        >
                          <NewText
                            component={"p"}
                            desktopFontSize={16}
                            desktopFontWeight={400}
                            color="#2768A3"
                          >
                            {linkToPdfFile[0]?.name}
                          </NewText>
                        </StyledPdfLink>
                      )}
                    </StyledWhiteOverlay>
                  </StyledImageWrapper>
                </Link>
              </StyledProjectItem>
            )
          })}
        </StyledProjectDiv>
      </StyledDiv>
    </Container>
  )
}

export default EligibleProjects
