import styled from "@emotion/styled"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import { AccordionButtonIcon, Container, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty, getNonUmbracoUrl, getUmbracoMediaUrl } from "utils"

const StyledDiv = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-top: 32px;
  .Mui-expanded {
    background-color: #f7f7f7;
  }
  .MuiPaper-root {
    &::before {
      display: none;
    }
  }
  .MuiAccordionSummary-content {
    align-items: center;
    gap: 16px;
  }
  .MuiAccordionDetails-root {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #f7f7f7;
    border-top: 1px solid #6f6f6f;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-left: 36px;
      padding-right: 36px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
    gap: 40px;
    padding-bottom: 32px;
  }
`

const StyledTextWrapp = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 80%;
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`

const StyledButtonLink = styled(Link)`
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border: 2px black solid;
  width: fit-content;
  display: flex;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

function SingleAccordion({
  icon,
  title,
  content,
  buttonLink1,
  button2,
  index,
}: {
  icon: string
  title: string
  content: string
  buttonLink1: any
  button2: any
  index: number
}) {
  const [expand, setExpand] = useState(index === 0 ? true : false)
  return (
    <Accordion
      expanded={expand}
      onChange={() => setExpand((el) => !el)}
      style={{
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.16)",
        borderRadius: 16,
        margin: "0px",
      }}
    >
      <AccordionSummary>
        <Image
          src={getUmbracoMediaUrl(icon)}
          alt={title}
          width={48}
          height={48}
          style={{ objectFit: "contain" }}
        />
        <NewText
          component={"p"}
          desktopFontSize={16}
          desktopFontWeight={600}
          mobileFontSize={14}
          color={sharedColors.black}
        >
          {title}
        </NewText>
        <AccordionButtonIcon
          style={{
            transform: expand ? undefined : "rotate(180deg)",
            marginRight: "auto",
          }}
        />
        {/* {expand ? (
                  <IconButton
                    onClick={() => {
                      setExpand(false)
                    }}
                  >
                    <AccordionButtonIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setExpand(true)}>
                    <AccordionButtonIcon />
                  </IconButton>
                )} */}
      </AccordionSummary>
      <AccordionDetails>
        <StyledTextWrapp>
          <NewText
            dangerouslySetInnerHTML={{ __html: content }}
            desktopFontSize={16}
            desktopFontWeight={400}
            component={"div"}
            color={sharedColors.black}
            desktopLineHeight={1.4}
          />
        </StyledTextWrapp>
        <StyledButtonWrapper>
          {buttonLink1 && buttonLink1.length > 0 && (
            <StyledButtonLink href={getNonUmbracoUrl(buttonLink1[0].url)}>
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopFontWeight={600}
                mobileFontSize={14}
                color={sharedColors.black}
              >
                {buttonLink1[0].name}
              </NewText>
            </StyledButtonLink>
          )}
          {button2 && button2.length > 0 && (
            <StyledButtonLink href={getNonUmbracoUrl(button2[0].url)}>
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopFontWeight={600}
                mobileFontSize={14}
                color={sharedColors.black}
              >
                {button2[0].name}
              </NewText>
            </StyledButtonLink>
          )}
        </StyledButtonWrapper>
      </AccordionDetails>
    </Accordion>
  )
}

type Props = {
  data: any
}

const EligibilityAccordion = ({ data }: Props) => {
  const eligibilityItems = extractProperty(data, "eligibilityItems")
  return (
    <Container>
      <StyledDiv>
        {eligibilityItems.map((item: any, index: number) => {
          const icon = extractProperty(item, "icon")
          const title = extractProperty(item, "title")
          const content = extractProperty(item, "content")
          const buttonLink1 = extractProperty(item, "buttonLink1")
          const button2 = extractProperty(item, "button2")
          return (
            <SingleAccordion
              index={index}
              key={index}
              icon={icon}
              title={title}
              content={content}
              buttonLink1={buttonLink1}
              button2={button2}
            />
          )
        })}
      </StyledDiv>
    </Container>
  )
}

export default EligibilityAccordion
