import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useRef, useState } from "react"
import VideoMuteIcon from "ui/icons/video/VideoMuteIcon"
import VideoPauseIcon from "ui/icons/video/VideoPauseIcon"
import VideoPlayIcon from "ui/icons/video/VideoPlayIcon"
import VideoUnmuteIcon from "ui/icons/video/VideoUnmuteIcon"

const StyledVideoContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledVideoButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 5px;
  z-index: 3;
  button {
    padding: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: 70px;
    bottom: 0px;
    right: 16px;
  }
`

const SliderVideoComp = ({ url }: { url: string }) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  const handleMute = () => {
    if (videoRef.current) {
      if (isMuted) {
        videoRef.current.muted = false
        setIsMuted(false)
      } else {
        videoRef.current.muted = true
        setIsMuted(true)
      }
    }
  }

  return (
    <StyledVideoContainer>
      <StyledVideo
        preload="auto"
        autoPlay
        loop
        muted
        playsInline
        ref={videoRef}
        onLoadedData={() => videoRef.current?.play()}
      >
        <source src={url} type="video/mp4" />
      </StyledVideo>
      <StyledVideoButtonContainer>
        <IconButton
          aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
          onClick={() => handlePlay()}
          disableRipple
        >
          {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
        </IconButton>
        <IconButton
          aria-label={isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"}
          onClick={() => handleMute()}
          disableRipple
        >
          {isMuted ? <VideoMuteIcon /> : <VideoUnmuteIcon />}
        </IconButton>
      </StyledVideoButtonContainer>
    </StyledVideoContainer>
  )
}

export default SliderVideoComp
