"use client"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import { Fragment } from "react"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Desktop,
  LeftArrow,
  Mobile,
  NewText,
  RightArrow,
  TransitionUp,
  transitionDelayTime,
  useInViewConfig,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import { Group, getUmbracoMediaUrl } from "utils"

const StyledSlideContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 242px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 16px;
  min-height: 168px;
  text-decoration: none;
`
const StyledDesktopSlideContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  width: 242px;
  min-height: 168px;
`
const StyledGridContainer = styled.div<{ grid: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.grid}, 1fr);
  column-gap: 24px;
  row-gap: 40px;
  width: min-content;
`

const StyledLinkContainer = styled.div`
  justify-self: end;
  display: flex;
  justify-content: end;
`

const StyledTexts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
type Props = {
  data: Group
  grid?: number
  textOnly?: boolean
}

export const PartnerSlider: React.FC<Props> = ({ data, grid = 4, textOnly }) => {
  const { ref, inView } = useInView(useInViewConfig)
  const locale = useLocale()
  return (
    <>
      <Mobile>
        <Swiper slidesPerView={"auto"} spaceBetween={24} style={{ padding: "10px 6px" }}>
          {data.map((item, index: number) => {
            if (item.memberName) {
              return (
                <Fragment key={index.toString()}>
                  {textOnly ? (
                    <SwiperSlide key={index.toString()} style={{ maxWidth: "max-content" }}>
                      <StyledTexts>
                        <NewText
                          sx={{
                            borderBlockEndWidth: "2px",
                            borderBlockEndColor: "#F7F7F7",
                          }}
                          component={"span"}
                          desktopFontSize={14}
                          mobileFontSize={12}
                          color={sharedColors.black}
                        >
                          {item.memberName}
                        </NewText>
                      </StyledTexts>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide key={index.toString()} style={{ width: "242px" }}>
                      <StyledSlideContainer
                        key={index.toString()}
                        href={item.url || ""}
                        target="_blank"
                      >
                        {item.lOGO && (
                          <Image
                            src={getUmbracoMediaUrl(item.lOGO)}
                            width={100}
                            height={64}
                            alt={item.memberName || ""}
                            style={{
                              objectFit: "contain",
                              marginInlineEnd: "auto",
                              width: "auto",
                              height: 64,
                            }}
                          />
                        )}
                        <NewText
                          desktopFontSize={14}
                          mobileFontSize={14}
                          mobileFontWeight="400"
                          mobileLineHeight={"20.16px"}
                          component="p"
                          color={"black"}
                          style={{ marginBlockStart: 8, height: 40 }}
                        >
                          {item.memberName}
                        </NewText>
                        <StyledLinkContainer>
                          {locale === "he" ? <LeftArrow /> : <RightArrow />}
                        </StyledLinkContainer>
                      </StyledSlideContainer>
                    </SwiperSlide>
                  )}
                </Fragment>
              )
            }
          })}
        </Swiper>
      </Mobile>
      <Desktop>
        {textOnly ? (
          <StyledTexts>
            {data.map((item, index: number) => {
              if (item.memberName) {
                return (
                  <NewText
                    key={index}
                    style={{
                      borderInlineEnd: "2px solid #F7F7F7",
                      paddingInlineEnd: "16px",
                    }}
                    component={"span"}
                    desktopFontSize={14}
                    mobileFontSize={12}
                    color={sharedColors.black}
                  >
                    {item.memberName}
                  </NewText>
                )
              }
            })}
          </StyledTexts>
        ) : (
          <StyledGridContainer grid={grid} ref={ref}>
            {data.map((item, index: number) => {
              if (item.url) {
                return (
                  <Fragment key={index}>
                    <TransitionUp key={index} $inView={inView} $delay={index * transitionDelayTime}>
                      <StyledDesktopSlideContainer href={item.url || ""} target="_blank">
                        {item.lOGO && (
                          <Image
                            src={getUmbracoMediaUrl(item.lOGO)}
                            width={100}
                            height={64}
                            alt={item.memberName || ""}
                            style={{
                              objectFit: "contain",
                              marginInlineEnd: "auto",
                              width: "auto",
                              height: 64,
                            }}
                          />
                        )}

                        <NewText
                          desktopFontSize={14}
                          desktopFontWeight={400}
                          desktopLineHeight={"20px"}
                          mobileFontSize={12}
                          mobileFontWeight="400"
                          mobileLineHeight={"17px"}
                          component="p"
                          color={"black"}
                          style={{ marginBlockStart: 8, minHeight: 40 }}
                        >
                          {item.memberName}
                        </NewText>
                        <StyledLinkContainer>
                          {locale === "he" ? <LeftArrow /> : <RightArrow />}
                        </StyledLinkContainer>
                      </StyledDesktopSlideContainer>
                    </TransitionUp>
                  </Fragment>
                )
              }
            })}
          </StyledGridContainer>
        )}
      </Desktop>
    </>
  )
}
export default PartnerSlider
