import styled from "@emotion/styled"
import { Button, Checkbox, IconButton, Modal } from "@mui/material"
import { LobbyAvailableFilters } from "api"
import { Dispatch, SetStateAction, useState } from "react"
import { CheckboxIcon, CheckedCheckboxIcon, NewText, XIcon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { jobFilter } from "utils"
import { CareerLobbyMessages } from "../Careers"
import { FilterState } from "./Jobs"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: 584px;
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    width: max-content;
  }

  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FiltersContainer = styled.div`
  height: 436px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px 0px 16px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: white; /* or add it to the track */
  }
  ::-webkit-scrollbar-thumb {
    background: #2768a3;
    border-radius: 200px;
  }
`
const CtaContainer = styled.div`
  border-top: 2px solid rgba(247, 247, 247, 1);
  display: flex;
  justify-content: space-between;
  padding: 16px;
  height: 80px;
`

const SingleFilterCont = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(247, 247, 247, 1);
`
const StyledFilterGrid = styled.div`
  display: grid;

  grid-auto-flow: column;
  grid-template-rows: repeat(4, minmax(50px, auto));
  ${(props) => props.theme.breakpoints.up("md")} {
    row-gap: 0px;
    grid-template-columns: 1fr 1fr;
    column-gap: 90px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    grid-auto-flow: unset;
    grid-template-rows: unset;
  }
`

const FilterItem = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: start;
    flex-direction: row;
  }
  gap: 16px;
`

const Header = styled.div`
  border-radius: 16px 16px 0 0;
  height: 68px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${sharedColors.oldBrandBlue};
`

const CloseButton = styled(IconButton)``

const ApplyButton = styled(Button)`
  font-size: 18px;
  padding: 8px 16px;
`

const ClearButton = styled(Button)`
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  color: #2768a3;
  color: ${sharedColors.propBlue};
`

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0px;
`

function FilterGrid({
  filters,
  label,
  filterKey,
  setState,
  state,
}: {
  filters: LobbyAvailableFilters[keyof LobbyAvailableFilters]
  label: string
  filterKey: keyof FilterState
  setState: Dispatch<SetStateAction<FilterState>>
  state: FilterState
}) {
  const [showAll] = useState(true)
  const allSelected = state[filterKey].length === filters.length || state[filterKey].length === 0
  const selectAll = () => {
    setState((prevState) => ({
      ...prevState,
      [filterKey]: [],
    }))
  }
  const selectOne = (num: number) => {
    setState((prevState) => {
      const isSelected = prevState[filterKey].includes(num)
      if (isSelected) {
        return {
          ...prevState,
          [filterKey]: prevState[filterKey].filter((id: number) => id !== num),
        }
      }
      if (state[filterKey].length + 1 === filters.length) {
        return {
          ...prevState,
          [filterKey]: [],
        }
      }
      return {
        ...prevState,
        [filterKey]: [...prevState[filterKey], num],
      }
    })
  }
  const showValues = !showAll ? filters.slice(0, 8) : filters
  return (
    <SingleFilterCont>
      <NewText
        component={"h4"}
        desktopFontSize={16}
        mobileFontSize={14}
        desktopFontWeight={600}
        color="black"
      >
        {label}
      </NewText>
      <StyledFilterGrid>
        <FilterItem>
          <StyledCheckbox
            checked={allSelected}
            defaultChecked={allSelected}
            onClick={selectAll}
            checkedIcon={<CheckedCheckboxIcon color={sharedColors.oldBrandBlue} />}
            icon={<CheckboxIcon />}
          />
          <NewText
            component={"span"}
            desktopFontSize={16}
            mobileFontSize={14}
            color={allSelected ? sharedColors.oldBrandBlue : "black"}
            desktopFontWeight={allSelected ? 600 : 400}
          >
            {filterKey === "jobScopeIds" && "הצגת הכל"}
            {filterKey === "companyIds" && "כל החברות"}
            {filterKey === "areaIds" && "כל האזורים"}
          </NewText>
        </FilterItem>
        {showValues.map((el) => {
          const isSelected = state[filterKey].some((filt: number) => filt === el.id)
          return (
            <FilterItem key={el.id}>
              <StyledCheckbox
                defaultChecked={isSelected && !allSelected}
                checked={isSelected && !allSelected}
                onClick={() => {
                  selectOne(el.id)
                  jobFilter(el.name, filterKey, "Filter - projects")
                }}
                checkedIcon={<CheckedCheckboxIcon color={sharedColors.oldBrandBlue} />}
                icon={<CheckboxIcon />}
              />
              <NewText
                component={"span"}
                desktopFontSize={16}
                mobileFontSize={14}
                color={isSelected && !allSelected ? sharedColors.oldBrandBlue : "black"}
                desktopFontWeight={isSelected && !allSelected ? 600 : 400}
              >
                {el.name}
              </NewText>
            </FilterItem>
          )
        })}
      </StyledFilterGrid>
    </SingleFilterCont>
  )
}

type Props = {
  open: boolean
  handleClose: () => void
  filters: LobbyAvailableFilters
  setState: Dispatch<SetStateAction<FilterState>>
  setInterimState: Dispatch<SetStateAction<FilterState>>
  interimState: FilterState
  messages: CareerLobbyMessages
  clearAll: () => void
  companyDivisionId: number[]
}

export default function FiltersModal({
  handleClose,

  open,
  filters,
  setState,
  interimState,
  setInterimState,
  messages,
  clearAll,
  companyDivisionId,
}: Props) {
  const isFilteredAny = Object.values(interimState).some((arr) => arr.length > 0)
  const applyFilters = () => {
    jobFilter("ביצוע סינון", "ביצוע סינון", "קריירה פילטר")
    handleClose()
    setState((prevState) => ({
      ...interimState,
      professionIds: prevState.professionIds,
    }))
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="filters" aria-describedby="filters">
      <Container>
        <Header>
          <NewText component={"h3"} desktopFontSize={32} mobileFontSize={24} color="white">
            מסננים
          </NewText>
          <CloseButton
            onClick={() => {
              handleClose()
              jobFilter("close", "close", "קריירה פילטר")
            }}
          >
            <XIcon color="white" stroke="white" />
          </CloseButton>
        </Header>
        <Content>
          <FiltersContainer>
            <FilterGrid
              filterKey={"areaIds"}
              filters={filters["avialableAreas"]}
              label={messages.locaiton}
              state={interimState}
              setState={setInterimState}
            />
            {companyDivisionId.length === 0 && (
              <FilterGrid
                filterKey={"companyIds"}
                filters={filters["avialableCompanies"]}
                label={messages.company}
                state={interimState}
                setState={setInterimState}
              />
            )}

            <FilterGrid
              filterKey={"jobScopeIds"}
              filters={filters["avialableJobScopes"]}
              label={messages.jobType}
              state={interimState}
              setState={setInterimState}
            />
          </FiltersContainer>
          <CtaContainer>
            {isFilteredAny ? (
              <ClearButton
                variant="text"
                onClick={() => {
                  clearAll()
                  jobFilter("איפוס", "איפוס", "קריירה פילטר")
                }}
              >
                איפוס
              </ClearButton>
            ) : (
              <div />
            )}
            <ApplyButton variant="outlined" onClick={applyFilters}>
              ביצוע סינון
            </ApplyButton>
          </CtaContainer>
        </Content>
      </Container>
    </Modal>
  )
}
