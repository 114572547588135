import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors as colors } from "ui/theme/colors"
import { extractImage } from "utils"
import { extractProperty } from "utils/extractProperty"

const StyledSwiperContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 240px 1fr;
    grid-gap: 40px;
  }
`

const StyledTextContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-right: 160px;
  }
  p {
    margin: 0px;
  }
`

const StyledImageContainer = styled.div`
  width: 140px;
  position: relative;
  height: 140px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 240px;
    height: 240px;
  }
`

const StyledBox = styled(Box)`
  max-width: 90%;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: auto;
  }
`

type Props = { item: any }

function GalleryItem({ item }: Props) {
  const image = extractImage(item, "image")
  const title = extractProperty(item, "title")
  const content = extractProperty(item, "content")
  const recommendName = extractProperty(item, "recommendName")
  const position = extractProperty(item, "position")
  return (
    <StyledSwiperContainer>
      <StyledImageContainer>
        <ImageWithAlt image={image} fill loading="eager" />
      </StyledImageContainer>
      <StyledTextContainer>
        <NewText
          component="p"
          desktopFontSize={32}
          desktopLineHeight="36px"
          mobileFontSize={24}
          mobileLineHeight="28px"
          color={colors.white}
        >
          {title}
        </NewText>
        <NewText
          component="div"
          desktopFontSize={18}
          desktopLineHeight="25.92px"
          mobileFontSize={16}
          mobileLineHeight="23px"
          color={colors.white}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <StyledBox>
          <NewText
            component="p"
            desktopFontSize={18}
            desktopLineHeight="26px"
            desktopFontWeight={600}
            mobileFontSize={16}
            mobileLineHeight="23px"
            color={colors.white}
          >
            {recommendName}
          </NewText>
          <NewText
            component="p"
            desktopFontSize={18}
            desktopLineHeight="25.92px"
            mobileFontSize={16}
            mobileLineHeight="23px"
            color={colors.white}
          >
            {position}
          </NewText>
        </StyledBox>
      </StyledTextContainer>
    </StyledSwiperContainer>
  )
}

export default GalleryItem
