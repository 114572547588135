import styled from "@emotion/styled"
import { Container, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { extractProperty } from "utils/extractProperty"

const StyledSubtitleContainer = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 675px;
  }
`

type Props = {
  data: any
  id?: string
}

function Content({ data, id }: Props) {
  const dataToRender = data?.propertyValue?.blocks?.[0]
  const contentTitle = extractProperty(dataToRender, "conetntTitle")
  const subTitle = extractProperty(dataToRender, "subTitle")
  const innerText = extractProperty(dataToRender, "innerText")
  return (
    <Container id={id}>
      <NewText
        component="p"
        desktopFontSize={32}
        desktopLineHeight="36px"
        mobileFontSize={24}
        mobileLineHeight="28px"
        color={colors.black}
      >
        {contentTitle}
      </NewText>
      <StyledSubtitleContainer>
        <NewText
          component="div"
          desktopFontSize={18}
          desktopLineHeight="25.92px"
          mobileFontSize={16}
          mobileLineHeight="23px"
          color={colors.black}
          dangerouslySetInnerHTML={{ __html: subTitle || innerText }}
        />
      </StyledSubtitleContainer>
    </Container>
  )
}

export default Content
