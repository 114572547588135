"use client"
import styled from "@emotion/styled"
import "swiper/css"
import { Container, NewText } from "ui"
import { extractProperty } from "utils"

const StyledContainer = styled.div`
  padding-top: 32px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 40px;
  }
`
type Props = {
  data: any
}

export const TitleContent: React.FC<Props> = ({ data }) => {
  const title = extractProperty(data, "conetntTitle")
  const innerText = extractProperty(data, "innerText")
  return (
    <Container>
      <StyledContainer>
        <NewText
          desktopFontSize={40}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          mobileFontSize={32}
          mobileFontWeight="400"
          mobileLineHeight={"112.5%"}
          component="h1"
          color={"black"}
        >
          {title}
        </NewText>
        <NewText
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="26px"
          mobileFontSize={16}
          mobileFontWeight="400"
          mobileLineHeight={"23px"}
          component="div"
          color={"black"}
          dangerouslySetInnerHTML={{ __html: innerText }}
          style={{
            maxWidth: "900px",
          }}
        />
      </StyledContainer>
    </Container>
  )
}
export default TitleContent
