import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import Image from "next/image"
import { useState } from "react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"

import { Swiper, SwiperSlide } from "swiper/react"
import { Container, Desktop, LeftArrow, RightArrow, XIcon } from "ui"
import { ApartmentUmbraco } from "utils/projects"
import ApartmentSpec from "./ApartmentSpec"

const StyledGalleryDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  img {
    border-radius: 16px;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
  }
`

// const StyledRightDiv = styled.div`
//   height: 444px;
//   position: relative;
// `

// const StyledLeftDiv = styled.div`
//   height: 444px;
//   position: relative;
//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     gap: 16px;
//   }
// `

// const SytledImageWrapper = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
// `

// const StyledExpandIcon = styled.div`
//   position: absolute;
//   top: 16px;
//   left: 16px;
// `

// const StyledVirtualDiv = styled.div`
//   box-shadow: 0px 4px 8px 0px #00000029;
//   position: absolute;
//   bottom: 16px;
//   left: 16px;
//   background: #0000007a;
//   padding: 16px;
//   height: 39px;
//   border-radius: 200px;
//   display: flex;
//   align-items: center;
//   a {
//     text-decoration: none;
//   }
// `

const StyledGalleryPopup = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  position: fixed;
  z-index: 10;
  top: 64px;
  left: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 98px;
  }
`

const StyledCloseDiv = styled.div`
  position: absolute;
  top: 32px;
  right: 16px;
  button {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: white;
    }
  }
  svg {
    path {
      stroke: black;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 32px;
    right: 0px;
  }
`

const StyledContainer = styled(Container)`
  position: relative;
  height: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    img {
      border-radius: 16px;
    }
  }
`

const StyledImagesDiv = styled.div`
  height: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const StyledMainImage = styled.div`
  position: relative;
  height: 265px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: calc(100% - 100px);
    height: calc(100% - 250px);
  }
`

const StyledThumbImages = styled.div`
  width: 100%;
  padding-top: 16px;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: calc(100% - 100px);
  }
`

const StyledSlideDiv = styled.div<{ $active: boolean }>`
  height: 108px;
  position: relative;

  border: ${(p) => (p.$active ? "2px solid white" : "none")};
  border-radius: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 200px;
  }
`

const StyledArrowDiv = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  z-index: 10;

  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    background-color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: white;
    }
  }
`

const StyledMain = styled.div``

const StyledApartmentSpecDiv = styled.div`
  padding-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
  }
`

const images = [
  "/images/gallery/image.png",
  "/images/gallery/image1.png",
  "/images/gallery/image2.png",
  "/images/gallery/image3.png",
  "/images/gallery/image4.png",
  "/images/gallery/image1.png",
  "/images/gallery/image2.png",
  "/images/gallery/image3.png",
]
type Props = {
  apartment: ApartmentUmbraco
}
function ApartmentGallery({ apartment }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  //   const [swiperRef, setSwiperRef] = useState<any>(null)
  const locale = useLocale()

  return (
    <StyledMain>
      <StyledGalleryDiv>
        {/* <StyledRightDiv>
          <Image
            src={"/images/gallery/image.png"}
            alt="poster 1"
            layout="fill"
            objectFit="cover"
          />
          <StyledExpandIcon>
            <IconButton onClick={() => setIsOpen(true)}>
              <GalleryIcon />
            </IconButton>
          </StyledExpandIcon>
          <StyledVirtualDiv>
            <Link href={"/"}>
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopLineHeight={"23px"}
                mobileFontSize={14}
                mobileLineHeight={"20px"}
                desktopFontWeight={400}
                color={sharedColors.white}
              >
                לסיור וירטואלי
              </NewText>
            </Link>
          </StyledVirtualDiv>
        </StyledRightDiv>
        <Desktop>
          <StyledLeftDiv>
            <SytledImageWrapper>
              <Image
                src={"/images/gallery/image1.png"}
                alt="poster 2"
                layout="fill"
                objectFit="cover"
              />
            </SytledImageWrapper>
            <SytledImageWrapper>
              <Image
                src={"/images/gallery/image2.png"}
                alt="poster 3"
                layout="fill"
                objectFit="cover"
              />
            </SytledImageWrapper>
            <SytledImageWrapper>
              <Image
                src={"/images/gallery/image3.png"}
                alt="poster 4"
                layout="fill"
                objectFit="cover"
              />
            </SytledImageWrapper>
            <SytledImageWrapper>
              <Image
                src={"/images/gallery/image4.png"}
                alt="poster 5"
                layout="fill"
                objectFit="cover"
              />
            </SytledImageWrapper>
          </StyledLeftDiv>
        </Desktop> */}
      </StyledGalleryDiv>
      {isOpen && (
        <StyledGalleryPopup>
          <StyledContainer>
            <StyledCloseDiv>
              <IconButton onClick={() => setIsOpen(false)}>
                <XIcon />
              </IconButton>
            </StyledCloseDiv>
            <StyledImagesDiv>
              <StyledMainImage>
                <Image
                  src={images[activeIndex]}
                  layout="fill"
                  style={{ objectFit: "cover" }}
                  alt="poster"
                />
              </StyledMainImage>
              <StyledThumbImages>
                <Swiper
                  onSlideChange={(swiper) => {
                    setActiveIndex(swiper.activeIndex)
                  }}
                  navigation={{
                    nextEl: `.arrow-left`,
                    prevEl: `.arrow-right`,
                  }}
                  hashNavigation
                  modules={[Navigation]}
                  slidesPerView={2.2}
                  breakpoints={{
                    960: {
                      slidesPerView: 4.5,
                    },
                  }}
                  //   onSwiper={setSwiperRef}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  spaceBetween={"16px"}
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <StyledSlideDiv $active={index === activeIndex}>
                        <Image
                          key={index}
                          src={image}
                          fill
                          style={{ objectFit: "cover" }}
                          alt={`poster ${index}`}
                        />
                      </StyledSlideDiv>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Desktop>
                  <StyledArrowDiv>
                    <IconButton className={`arrow-right arrow`}>
                      {locale === "en" ? <LeftArrow /> : <RightArrow />}
                    </IconButton>

                    <IconButton className={`arrow-left arrow`}>
                      {locale === "en" ? <RightArrow /> : <LeftArrow />}
                    </IconButton>
                  </StyledArrowDiv>
                </Desktop>
              </StyledThumbImages>
            </StyledImagesDiv>
          </StyledContainer>
        </StyledGalleryPopup>
      )}
      <StyledApartmentSpecDiv>
        <ApartmentSpec apartment={apartment} />
      </StyledApartmentSpecDiv>
    </StyledMain>
  )
}

export default ApartmentGallery
